import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'password' ]

  togglePassword(event) {
    if (this.passwordTarget.type == "password") {
      this.passwordTarget.type = "text"
      event.currentTarget.classList.add('fe-eye')
      event.currentTarget.classList.remove('fe-eye-off')
    } else {
      this.passwordTarget.type = "password"
      event.currentTarget.classList.remove('fe-eye')
      event.currentTarget.classList.add('fe-eye-off')
    }
  }
}
