// Used to open bootstrap modals on page load

import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = [ 'modal' ]

  connect() {
    let checkInModal = this.modalTarget;
    let myModal = new bootstrap.Modal(checkInModal);
  
    myModal.show();
  }
}
